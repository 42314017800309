import { initializeApp } from "firebase/app";
import { getDatabase, ref as dbRef, set, push } from "firebase/database";
import { getStorage, ref as storageRef, uploadBytes } from "firebase/storage";
import { getAuth } from "firebase/auth"; // Import Firebase Authentication

// Configuração do Firebase
const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID
};

// Inicializa o Firebase
const app = initializeApp(firebaseConfig);

// Obtém as referências para o banco de dados, armazenamento e autenticação
const database = getDatabase(app);
const storage = getStorage(app);
const auth = getAuth(app); // Inicializa o Firebase Authentication

export { database, storage, auth, dbRef, storageRef, set, push, uploadBytes };
