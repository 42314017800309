import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

const ProtectedRoute = ({ userType, allowedRoles, children }) => {
    const location = useLocation();

    if (!userType) {
        return <Navigate to="/" state={{ from: location }} replace />;
    }

    if (allowedRoles.includes(userType)) {
        return children;
    } else {
        return <Navigate to="/" replace />;
    }
};

export default ProtectedRoute;
