import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { onAuthStateChanged } from 'firebase/auth';
import { ref, onValue } from 'firebase/database';
import { auth, database } from './firebase';
import Form from './screens/Form';
import Files from './screens/Files';
import ProtectedRoute from './components/ProtectedRoute';
import Login from './screens/Login';

function App() {
    const [user, setUser] = useState(null);
    const [userType, setUserType] = useState(null);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
            if (currentUser) {
                setUser(currentUser);
                const userRef = ref(database, `usuarios/${currentUser.uid}`);
                onValue(userRef, (snapshot) => {
                    const data = snapshot.val();
                    if (data) {
                        setUserType(data.tipo);
                    }
                });
            } else {
                setUser(null);
                setUserType(null);
            }
        });

        return () => unsubscribe();
    }, []);

    return (
        <Router>
            <Routes>
                <Route path="/" element={<Login />} />
                <Route
                    path="/Form"
                    element={
                        <ProtectedRoute userType={userType} allowedRoles={['user', 'admin']}>
                            <Form />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/Files"
                    element={
                        <ProtectedRoute userType={userType} allowedRoles={['admin']}>
                            <Files />
                        </ProtectedRoute>
                    }
                />
            </Routes>
        </Router>
    );
}

export default App;
